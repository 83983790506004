import React from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import { IntlProvider } from 'react-intl'
import { EPProvider } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import theme from './theme'
import Modal from './Modal'

const App = (props) => <IntlProvider
  locale={currentLocale}
  defaultLocale={defaultLocale}
  messages={messages[currentLocale]}>
    <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
        <Modal {...props} />
    </EPProvider>
</IntlProvider>

App.propTypes = {
  keycloakConfig: PropTypes.any,
}

export default hot(App)
