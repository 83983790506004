/* eslint-disable no-undef */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { getUser } from 'commons/js/api'
import useApi from 'commons/js/hooks/useApi'
import { GenericErrorModal } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import { reload } from '@elparking/utils'

export const TIME_OFFSET = 5000

const JanitorModal = ({
    milliseconds,
    getUser,
    reload,
    modal: Modal,
}) => {
    const [logged, setLogged] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
    const {
        response: { result: user, error },
        sendRequest: getIsUserLogged,
    } = useApi(getUser)
    const intervalRef = useRef(null)

    useEffect(() => {
        if (logged) {
            (!user || error) && setShowMessage(true)
        } else {
            user && setLogged(true)
        }
    }, [user, logged, error, setLogged, setShowMessage])

    useEffect(() => {
        if (milliseconds) {
            intervalRef.current = setInterval(() => {
                getIsUserLogged()
            }, milliseconds - TIME_OFFSET)
        }
        return () => {
            clearInterval(intervalRef.current)
        }
    })
    if (!showMessage || !milliseconds) {
        return null
    }
    return <Modal
      title={<FormattedMessage defaultMessage='La sesión se ha cerrado' />}
      message={<FormattedMessage defaultMessage='Recargue la página para continuar' />}
      buttonLabel={<FormattedMessage defaultMessage='Recargar' />}
      onClick={() => reload(true)}
    />
}

JanitorModal.defaultProps = {
    getUser,
    reload,
    modal: GenericErrorModal,
}

JanitorModal.propTypes = {
    milliseconds: PropTypes.number,
    getUser: PropTypes.func,
    reload: PropTypes.func,
    modal: PropTypes.func,
}

export default JanitorModal
